import { useRef, useState, useEffect } from "react";
import icon1svg from "../img/icon_1.svg";
import icon2svg from "../img/icon_2.svg";
import icon3svg from "../img/icon_3.svg";
import { Section } from "./Section";

import { AnimatePresence, motion } from "framer-motion";

export const SectionAbout = function () {
  const [currentSvg, setCurrentSvg] = useState(icon1svg);
  const [currentMenu, setCurrentMenu] = useState(1);

  const point1 = useRef(null);
  const point2 = useRef(null);
  const point3 = useRef(null);

  const switchMenu = (target) => {
    switch (target) {
      case "1":
        setCurrentMenu(1);
        setCurrentSvg(icon1svg);
        break;
      case "2":
        setCurrentMenu(2);
        setCurrentSvg(icon2svg);
        break;
      case "3":
        setCurrentMenu(3);
        setCurrentSvg(icon3svg);
        break;
      default:
    }
  };

  useEffect(() => {
    if (currentMenu == 1) {
      point1.current.className = "tabs__content active";
    }
  }, [currentMenu]);

  useEffect(() => {
    console.info("svg changed to:", currentSvg);
  }, [currentSvg]);

  return (
    <Section id="about">
      <div className="container">
        <div className="heading center">
          <h2>About us</h2>
          <h3>
            Outsource IT Services with the Most Talented Engineers in the
            Industry
          </h3>
        </div>
        <div className="tabs">
          <div className="row align-items-center">
            <div className="col-xxl-2 col-xl-3 col-lg-3 tab__nav d-flex">
              <div className="nav__inner w-100">
                <ul className="tabs__caption">
                  <li
                    key="point1"
                    className={currentMenu == 1 ? "active" : ""}
                    onClick={() => switchMenu("1")}
                  >
                    Who We are?
                  </li>
                  <li
                    key="point2"
                    className={currentMenu == 2 ? "active" : ""}
                    onClick={() => switchMenu("2")}
                  >
                    What We Do?
                  </li>
                  <li
                    key="point3"
                    className={currentMenu == 3 ? "active" : ""}
                    onClick={() => switchMenu("3")}
                  >
                    Why Us?
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xxl-10 col-xl-9 col-lg-9 tab__main d-flex">
              <div className="main__inner w-100">
                {currentMenu == 1 && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1, when: "beforeChildren" }}
                    ref={point1}
                    className="tabs__content active"
                  >
                    <div className="content__inner">
                      <div className="content">
                        <h3>who we are</h3>
                        <p style={{ textTransform: "none" }}>
                          We are a team of experienced IT professionals who are
                          passionate about delivering cutting-edge solutions to
                          businesses of all sizes. With a diverse range of
                          skills and expertise, we are able to tackle any
                          challenge and provide customized solutions that drive
                          growth and success.
                        </p>
                      </div>
                      <div className="image">
                        {
                          <>
                            <motion.img
                              initial={{ x: "200%" }}
                              exit={{ x: "200%" }}
                              animate={{ x: 0 }}
                              transition={{ duration: 1 }}
                              src={currentSvg}
                              alt=""
                            />
                          </>
                        }
                      </div>
                    </div>
                  </motion.div>
                )}

                {currentMenu == 2 && (
                  <motion.div
                    ref={point2}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1, when: "beforeChildren" }}
                    className="tabs__content active"
                  >
                    <div className="content__inner">
                      <div className="content">
                        <h3>What we Do?</h3>
                        <p style={{ textTransform: "none" }}>
                          We specialize in IT outsourcing services, including
                          software development, web development, and mobile app
                          development. We use the latest technologies and
                          methodologies to create high-quality products that
                          meet the unique needs of our clients.
                        </p>
                      </div>
                      <div className="image animated fadeInRight">
                        {
                          <>
                            <motion.img
                              initial={{ x: "200%" }}
                              exit={{ x: "200%" }}
                              animate={{ x: 0 }}
                              transition={{ duration: 1 }}
                              src={currentSvg}
                              alt=""
                            />
                          </>
                        }
                      </div>
                    </div>
                  </motion.div>
                )}

                {currentMenu == 3 && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1, when: "beforeChildren" }}
                    ref={point3}
                    className="tabs__content active"
                  >
                    <div className="content__inner">
                      <div className="content">
                        <h3>Why Us?</h3>
                        <ul>
                          <li>
                            We establish long-term partnerships with our clients
                          </li>
                          <li>
                            We deliver innovative solutions that drive business
                            growth
                          </li>
                          <li>
                            We stay ahead of the curve with the latest
                            technologies and methodologies
                          </li>
                        </ul>
                      </div>
                      <div className="image animated fadeInRight">
                        {
                          <>
                            <motion.img
                              initial={{ x: "200%" }}
                              exit={{ x: "200%" }}
                              animate={{ x: 0 }}
                              transition={{ duration: 1 }}
                              src={currentSvg}
                              alt=""
                            />
                          </>
                        }
                      </div>
                    </div>
                  </motion.div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
