import logo from './logo.svg';
import './App.css';
import { Header } from './sections/Header';
import { Banner } from './sections/Banner';
import { SectionClients } from './sections/SectionClients';
import { SectionAbout } from './sections/SectionAbout';
import { SectionTechnologies } from './sections/SectionTechnologies';
import { SectionPortfolio } from './sections/SectionPortfolio';
import { Footer } from './sections/Footer';
import { SectionContact } from './sections/SectionContact';

function App() {
  return (
    <>
      <Header>
        <Banner />
      </Header>

      <main>
        <SectionClients />
        <SectionAbout />
        <SectionTechnologies />
        <SectionPortfolio />

        <SectionContact />
      </main>

      <Footer />
    </>
  );
}

export default App;
