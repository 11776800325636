import { Section } from "./Section";

export const SectionContact = function () {
    return (
        <Section id="contacts">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 contacts__heading d-flex">
                        <div className="heading__inner w-100">
                            <div className="heading">
                                <h3>Need more info?</h3>
                                <h2>Contact Us</h2>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 contacts__form d-flex">
                        <div className="form__inner w-100">
                            <form>
                                <div className="row">
                                    <div className="col-md-6 form__item">
                                        <div className="item__inner">
                                            <label>First Name</label>
                                            <input type="text" placeholder="Enter Your First Name" required />
                                        </div>
                                    </div>
                                    <div className="col-md-6 form__item">
                                        <div className="item__inner">
                                            <label>Last Name</label>
                                            <input type="text" placeholder="Enter Your Last Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 form__item">
                                        <div className="item__inner">
                                            <label>Email</label>
                                            <input type="email" placeholder="Enter Your Email" required />
                                        </div>
                                    </div>
                                    <div className="col-md-6 form__item">
                                        <div className="item__inner">
                                            <label>Phone number</label>
                                            <input type="email" placeholder="Enter Your Phone Number" />
                                        </div>
                                    </div>
                                    <div className="col-12 form__item">
                                        <div className="item__inner">
                                            <label>message</label>
                                            <textarea placeholder="Enter Your Message"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12 form__submit">
                                        <div className="submit__inner">
                                            <a data-fancybox data-src="#modal_thanks" className="btn btn-submit">Get Your Free Quote</a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="main__contacts">
                                <h5>Our address</h5>
                                Emperat Solutions LTD <br />
                                128 City Road, London, EC1V 2NX. <br />
                                United Kingdom <br /><br />

                                <h5>Email</h5>
                                <a href="mailto:info@emperat.solutions">info@emperat.solutions</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
};