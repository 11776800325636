import logo from '../img/logo.svg';

export const Menu = function () {
    return (
        <div className="header__top">
            <div className="container">
                <div className="row align-items-center">

                    <div className="col-xxl-3 col-md-2 col-5 header__logo">
                        <div className="logo">
                            <a href="/">
                                <img src={logo} alt="Emperat" />
                            </a>
                        </div>
                    </div>

                    <div className="col-xxl-9 col-md-10 col-7 header__menu sm-scroll">
                        <div className="menu">
                            <ul>
                                <li><a href="#about">About us</a></li>
                                <li><a href="#ourtech">Technologies</a></li>
                                <li><a href="#projects">Projects</a></li>
                                <li><a href="#contacts">Contact us</a></li>
                            </ul>
                            <a href="#contacts" className="free">Free Quote</a>
                        </div>
                        <div className="header_burger">
                            <div className="burger_button">
                                <span className="burger_color"></span>
                                <span className="burger_color"></span>
                                <span className="burger_color"></span>
                            </div>
                            <div className="menu_toggle">
                                <div className="toggle__inner">
                                    <ul>
                                        <li><a href="#about">About us</a></li>
                                        <li><a href="#ourtech">Technologies</a></li>
                                        <li><a href="#projects">Projects</a></li>
                                        <li><a href="#contacts">Contact us</a></li>
                                    </ul>
                                    <a href="#contacts" className="free">Free Quote</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}