import { motion } from "framer-motion"

export const Section = function (props) {
    return (
        <motion.section key={props.id} id={props.id}
            initial={{
                opacity: 0,
            }}
            whileInView={{
                opacity: 1
            }}

            transition={{
                delay: 0.2,
                duration: 0.4
            }}
        >
            {props.children}
        </motion.section>
    )
}