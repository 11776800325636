import logo from '../img/logo.svg';

import linkedin from '../img/post-linkedin.svg';
import facebook from '../img/facebook.svg';
import twitter from '../img/tw.svg';

export const Footer = function () {
    return (
        <footer>
		<section id="footer">
			<div className="container">
				<div className="row align-items-center">
					
					<div className="col-xxl-3 col-xl-2 col-lg-2 footer__logo footer__item">
						<div className="logo">
							<a href="/">
								<img src={logo} alt="" />
							</a>
						</div>
					</div>

					<div className="col-xxl-6 col-xl-8 col-lg-7 footer__menu footer__item">
						<div className="menu sm-scroll">
							<ul>
								<li><a href="#about">About us</a></li>
								<li><a href="#ourtech">Technologies</a></li>
								<li><a href="#projects">Projects</a></li>
								<li><a href="#contacts">Contact us</a></li>
							</ul>
						</div>
					</div>

					<div className="col-xxl-3 col-xl-2 col-lg-3 footer__contacts footer__item">
						<div className="contacts">
							<ul>
								<li><a href="#" target="_blank"><img src={linkedin} alt="" /></a></li>
								<li><a href="#" target="_blank"><img src={facebook} alt="" /></a></li>
								<li><a href="#" target="_blank"><img src={twitter} alt="" /></a></li>
							</ul>
						</div>
					</div>

				</div>
			</div>
		</section>
	</footer>
    )
}