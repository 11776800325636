import { Section } from "./Section";
import { motion } from "framer-motion";

export const SectionPortfolio = function () {
    return (
        <Section id="projects">
            <div className="container">
                <div className="heading center">
                    <h2>Our last projects</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id varius lectus maecenas nunc molestie et pulvinar.</p> */}
                </div>
                <div className="row" style={{
                    filter: "blur(5px)",
                }}>
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        className="col-sm-6 item d-flex">
                        <a href="#" target="_blank" className="item__inner">
                            <picture>
                                <source srcSet="./img/p1.webp" type="image/webp" />
                                <img src="./img/p1.png" />
                            </picture>
                        </a>
                    </motion.div>

                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        className="col-sm-6 item d-flex">
                        <a href="#" target="_blank" className="item__inner">
                            <picture>
                                <source srcSet="./img/p2.webp" type="image/webp" />
                                <img src="./img/p2.png" />
                            </picture>
                        </a>
                    </motion.div>

                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        className="col-sm-6 item d-flex">
                        <a href="#" target="_blank" className="item__inner">
                            <picture>
                                <source srcSet="./img/p3.webp" type="image/webp" />
                                <img src="./img/p3.png" />
                            </picture>
                        </a>
                    </motion.div>

                    <motion.div
                        whileHover={{ scale: 1.1 }} className="col-sm-6 item d-flex">
                        <a href="#" target="_blank" className="item__inner">
                            <picture>
                                <source srcSet="./img/p4.webp" type="image/webp" />
                                <img src="./img/p4.png" />
                            </picture>
                        </a>
                    </motion.div>
                </div>
            </div>
        </Section >
    );
};
