import c1 from '../img/c1.png';
import { Section } from './Section';

export const SectionClients = function () {
    return (
        <Section id="clients">
            <div className="container">
                <div className="clients_heading center">
                    <h2>Our clients made over 1M+ in software revenues when working with us</h2>
                </div>
                <div className="row">

                    <div className="col-lg-2 col-md-3 col-sm-4 col-6 box d-flex">
                        <div className="box__inner w-100">
                            <img src={c1} alt="" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-4 col-6 box d-flex">
                        <div className="box__inner w-100">
                            <img src={c1} alt="" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-4 col-6 box d-flex">
                        <div className="box__inner w-100">
                            <img src={c1} alt="" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-4 col-6 box d-flex">
                        <div className="box__inner w-100">
                            <img src={c1} alt="" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-4 col-6 box d-flex">
                        <div className="box__inner w-100">
                            <img src={c1} alt="" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-4 col-6 box d-flex">
                        <div className="box__inner w-100">
                            <img src={c1} alt="" />
                        </div>
                    </div>

                </div>
            </div>
        </Section>
    )
}