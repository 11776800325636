import inner11Svg from "../img/11.svg";

export const Banner = function () {
  return (
    <>
      <div className="header__main">
        <div className="container">
          <div className="main__content center">
            <h2>REALIZE YOUR IDEAS</h2>
            <h1>Bringing your ideas to life with IT outsourcing.</h1>
            <p style={{ textTransform: "none" }}>
              At our company, we aim to establish long-term partnerships with
              our clients and provide exceptional software development and web
              development services. We believe that the key to success is
              utilizing the latest technologies and constantly improving our
              skills. That's why our team of experienced developers regularly
              undergoes training and certifications to stay ahead of the curve
              and deliver innovative solutions that drive business growth.
            </p>
            <div className="box__btn">
              <a href="#" className="btn btn-line">
                Learn More
              </a>
              <a href="#" className="btn">
                Free Quote
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="main__image">
        <div className="container">
          <div className="image__inner">
            <img src={inner11Svg} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
