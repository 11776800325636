import inner11Svg from '../img/11.svg';
import { Menu } from './Menu';

export const Header = function (props) {
    return (
        <header>
            <Menu />
            {props.children}
        </header>
    );
};